import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Form } from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { dataSourceV2 } from "commons-ui/core/DataSource";
import MuiSelect from "commons-ui/core/MuiSelect";
import renderHTML from "react-render-html";
import { DeliveryCostPart } from "../../../DeliveryCostPart";
import Link from "next/link";
import MaskedInput from "react-text-mask";
import { useStore } from "../../../../context/state";
import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import { getRequestedTime, getMinDate } from "../utils";
import { prettyDateUSA, dateToIsoTZ } from "commons-ui/core/utils/date";
import "./style.styl";


function PhoneMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"_"}
        />
    );
}


export default function DeliveryForm(props) {
    const { item, itemId, defaultPostalCode, itemIdValue } = props;
    const [postalCode, setPostalCode] = useState(defaultPostalCode || "");
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [deliveryTime, setDeliveryTime] = useState("");
    const [cost, setCost] = useState(0);
    const [rule, setRule] = useState({});
    const [disabledDateDict, setDisabledDateDict] = useState({});
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const isRuleExist = Object.keys(rule).length !== 0 && rule.ecommerce_store_delivery_option_rule_id;

    const { isMobile, apiPath } = useStore();

    const request = () => {
        //if (!deliveryDate || !postalCode)
        setLoading(true);
        setCost(0);
        setRule({});
        setError("");
        let dateIso = dateToIsoTZ(deliveryDate ? new Date(deliveryDate) : new Date()).replace("+", "p");
        let dateNow = dateToIsoTZ(new Date()).replace("+", "p");
        dataSourceV2(`ecommerce_store_delivery_cost?index=${postalCode}&${itemId}=${itemIdValue}&date=${dateIso}&date_now=${dateNow}`, { url: apiPath })
            .then(v => {
                if (!v.message && v.item) {
                    console.log(123, v.item)
                    setCost(v.item.cost || 0);
                    setRule(v.item.rule || {});
                    if (Object.keys(v.item.rule || {}).length === 0 || !(v.item.rule || {}).ecommerce_store_delivery_option_rule_id) {
                        setError("Delivery is not available for this date. See DETAILS.");
                    }
                }
                setLoading(false);
            });
    };

    const requestAvailableDays = (postalCode_) => {
        setLoading(true);
        let dateNow = dateToIsoTZ(new Date()).replace("+", "p");
        dataSourceV2(`ecommerce_store_delivery_cost_period?index=${postalCode_ || postalCode}&${itemId}=${itemIdValue}&date_now=${dateNow}&period_days=90`, { url: apiPath })
            .then(v => {
                if (!v.message && v.item_list) {
                    const d = {}
                    v.item_list.forEach(item => {
                        if (!item.rule) {
                            d[item.date.split("T")[0]] = true
                        }
                    })
                    setDisabledDateDict(d)
                    console.log(11, v.item_list)
                }
                setLoading(false);
            });
    };


    const onCheck = () => {
        request();
    };

    useEffect(() => {
        setPostalCode("")
        setDeliveryDate(null)
    }, [itemIdValue]);

    useEffect(() => {
        //request();
    }, [postalCode, deliveryDate]);





    const disableDays = (date) => {
        const d = date.toLocaleDateString('en-CA');
        if (disabledDateDict[d]) {
            return true
        }

        return false;
    };

    console.log("deliveryDate", deliveryDate)


    const shift = rule.delivery_requested_date_shift_minutes || item.delivery_requested_date_shift_minutes;

    const timeOptions = deliveryDate ? getRequestedTime(item.delivery_requested_time_options, new Date(deliveryDate), shift, item.delivery_requested_time_options_interval) : []

    return (
        <div className="u-paddingLeft80 u-paddingRight80 u-xs-paddingHorizontal24 u-marginTop20">
            <div className="u-flex u-justifyContentSpaceBetween u-xs-flexColumn">
                <div className="u-maxWidth400 u-sizeFullWidth">
                    <TextField
                        label="Your Postal Code *"
                        margin="normal"
                        inputProps={{ maxLength: 5 }}
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => {
                            setPostalCode(e.target.value)
                            if (deliveryDate) {
                                setDeliveryDate(null)
                            }
                        }}
                        onBlur={(e) => requestAvailableDays(e.target.value)}
                        value={postalCode}
                        variant="outlined"
                    />

                    <DatePicker
                        disableToolbar
                        disablePast
                        variant="inline"
                        className="u-sizeFullWidth u-marginVertical12"
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        error={!!error}
                        helperText={error}
                        disabled={loading || !postalCode}
                        shouldDisableDate={disableDays}
                        minDate={getMinDate(shift, item.delivery_requested_time_options, item.delivery_requested_time_options_interval)}
                        id="date-picker-inline"
                        label="Requested Delivery Date *"
                        value={deliveryDate ? new Date(deliveryDate) : null}
                        onChange={(date) => {
                            if (disableDays(date)) {
                                return
                            }
                            if (date && !isNaN(date.getTime())) {
                                setDeliveryDate(date.toISOString());
                            }
                            console.log(222, date.toISOString())
                        }}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                    />
                    {
                        timeOptions.length ?
                            <MuiSelect items={timeOptions}
                                selected={deliveryTime}
                                margin="normal"
                                htmlFor={"delivery_requested_time_options_id"}
                                className="u-minWidth240 u-sizeFullWidth u-marginVertical12"
                                label="Requested Delivery Time *"
                                disabled={loading || !postalCode || !deliveryDate}
                                onSelect={(value) => setDeliveryTime(value)}
                            />
                            :
                            null
                    }
                    

                    <div className="u-sizeFullWidth u-flexCenter u-justifyContentCenter u-marginTop10 u-marginBottom20">
                        <Button
                            className="button-primary"
                            size="large"
                            variant="outlined"
                            onClick={onCheck}
                        >
                            Check
                        </Button>
                    </div>

                    {
                        isRuleExist ?
                            <div className="u-marginTop30 u-padding24 u-borderRadius10 u-border1 u-borderColorLighter">
                                <span className="u-borderGreenNormal u-fontSize16" style={{ color: "#016601" }}>Delivery Available</span>
                                <div className="u-marginTop8 u-fontSize14 u-sizeFullWidth">
                                    <span className="u-textColorDarker">Postal Code:</span><span className="u-marginLeft5 u-textColorNormal">{postalCode}</span>
                                </div>

                                <div className="u-marginTop8 u-fontSize14 u-sizeFullWidth">
                                    <span className="u-textColorDarker">Date:</span><span className="u-marginLeft5 u-textColorNormal">{deliveryDate ? prettyDateUSA(new Date(deliveryDate)) : "-"}</span>
                                </div>
                                {
                                    isRuleExist ?
                                        <div className="u-marginTop8 u-fontSize14 u-sizeFullWidth u-flexColumn">
                                            <span className="u-textColorDarker u-marginBottom5">Delivery Cost:</span>
                                            <DeliveryCostPart defaultCost={rule.cost}
                                                costJson={rule.delivery_cost_json}
                                                itemClassName="u-textColorNormal u-textAlignRight u-fontSize10" />
                                        </div>
                                        :
                                        null
                                }


                                {
                                    cost && item.delivery_cost_explanation && isRuleExist ?
                                        <div className="u-fontSize10 u-marginTop5 u-textColorNormal">
                                            {item.delivery_cost_explanation}
                                        </div>
                                        :
                                        null
                                }

                                {
                                    rule.packaging_cost || item.packaging_cost ?
                                        <div className="u-marginTop8 u-fontSize14 u-sizeFullWidth">
                                            <span className="u-textColorDarker">Packaging Cost:</span><span className="u-marginLeft5 u-textColorNormal">{"$" + (parseFloat(rule.packaging_cost || item.packaging_cost) || 0).toFixed(2)}</span>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    (rule.min_order || item.min_order) && isRuleExist ?
                                        <div className="u-flexColumn u-marginTop8 u-fontSize14 u-sizeFullWidth">
                                            <span className="u-flexCenter">
                                                <span className="u-textColorDarker">Min. Order:</span>
                                                <span className="u-marginLeft5 u-textColorNormal u-flexCenter">
                                                    {"$" + (parseFloat(rule.min_order || item.min_order) || 0).toFixed(2)}
                                                    <Tooltip title={"Min. order of in-house items before tax"}>
                                                        <HelpOutline fontSize="small" className="u-textColorNormal u-marginLeft5" />
                                                    </Tooltip>
                                                </span>
                                            </span>
                                        </div>
                                        :
                                        null

                                }

                                {
                                    (rule.max_order || item.max_order) && isRuleExist ?
                                        <div className="u-marginTop8 u-fontSize14 u-sizeFullWidth">
                                            <span className="u-textColorDarker">Max. Order:</span>
                                            <span className="u-marginLeft5 u-textColorNormal">
                                                {"$" + (parseFloat(rule.max_order || item.max_order) || 0).toFixed(2)}
                                            </span>
                                        </div>
                                        :
                                        null
                                }
                                <div className="u-flexCenter u-justifyContentCenter u-marginTop15">
                                    <Link href={"/location/" + rule.ecommerce_store_delivery_option_rule_id + "?postal_code=" + postalCode + "&date=" + deliveryDate + "&delivery_time=" + deliveryTime}>
                                        <a>
                                            <Button
                                                className="button-secondary"
                                                variant="outlined"
                                                size="large"
                                            >
                                                Go to Menu
                                            </Button>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            :
                            null
                    }



                </div>
                <div className="u-maxWidth400 u-sizeFullWidth">
                    <div className="u-textColorNormal u-marginTop8 u-xs-marginTop25 u-fontSize14 u-marginLeft20 u-xs-marginLeft0">
                        {item.description && renderHTML(item.description)}
                    </div>
                </div>
            </div>
        </div>
    );
}
