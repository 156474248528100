import React, { useState, useEffect, useMemo } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import { dataSourceV2 } from "commons-ui/core/DataSource";
import MuiSelect from "commons-ui/core/MuiSelect";
import Loading from "commons-ui/core/Loading";
import Dialog from "@material-ui/core/Dialog";
import renderHTML from "react-render-html";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
import { getRequestedTime, getMinDate } from "ecommerce-commons-ui/core/Checkout/CheckoutStepForm/Delivery/utils";
import { DeliveryCostPart } from "../../../DeliveryCostPart";
import { useStore } from "../../../../context/state";
import PickUpMap from "./PickUpMap";
import Link from "next/link";
import Button from "@material-ui/core/Button";
import "./style.styl";




export default function PickUpForm(props) {
  const { description, itemId, modelName, itemIdParent, itemIdValueParent, readOnly, onBack } = props;
  const [items, setItems] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isMobile, apiPath } = useStore();

  const request = () => {
    dataSourceV2(`${modelName}_list?${itemIdParent}=${itemIdValueParent}`, { url: apiPath })
      .then(v => {
        if (!v.message && v.item_list && v.item_list.length) {
          const itemList = v.item_list.filter(v => !["55169324-9bcc-4142-92e1-7e8d3c064014"].includes(v.ecommerce_store_delivery_option_pickup_id)).map((v) => {
            if ((v.name || "").toLowerCase().includes("pike place")) {
              v.lat = 47.60994657091181;
              v.lng = -122.34240557992925;
            }
            if ((v.name || "").toLowerCase().includes("century square")) {
              v.lat = 47.61044701322972;
              v.lng = -122.33712951917;
            }
            if ((v.name || "").toLowerCase().includes("south park")) {
              v.lat = 47.525510354211605;
              v.lng = -122.33109418547275;
            }
            if ((v.name || "").toLowerCase().includes("kent")) {
              v.lat = 47.368430;
              v.lng = -122.302580;
            }
            if ((v.name || "").toLowerCase().includes("columbia center")) {
              v.lat = 47.60449677014453;
              v.lng = -122.33046984434701;
            }
            if ((v.name || "").toLowerCase().includes("convention center")) {
              v.lat = 47.6144207;
              v.lng = -122.3302785;
            }
            return v;
          });
          setItems(itemList);
        }
        setLoading(false);
      });
  };


  useEffect(() => {
    if (itemIdValueParent) request();
  }, [itemIdValueParent]);


  if (loading) {
    return (
      <Loading className="u-marginBottom100" />
    );
  }


  //<Link href={"/stores/" + (v.slug || itemIdValue)}>
  //<PickUpFormDialog item={formOpen || undefined} onClose={() => setFormOpen(false)} isMobile={isMobile} />
  return (
    <React.Fragment>
      <div className="pickup-delivery-form u-flex u-justifyContentSpaceBetween u-marginTop10 u-xs-marginTop0 u-xs-flexColumn">
        <div className="u-sizeFullWidth">
          <List className="">
            {
              items.map((v, i) => {
                const itemIdValue = v[itemId];
                return (
                  <ListItem
                    key={i}
                    className="event-item u-paddingLeft80 u-paddingRight80 u-xs-paddingHorizontal24 u-marginBottom30 u-textColorNormal u-fontSize12"
                  >
                    <div className="u-sizeFullWidth u-flex u-justifyContentSpaceBetween u-xs-flexColumn">
                      <div className="u-sizeFullWidth u-maxWidth600">
                        <div id={itemIdValue} name={itemIdValue} className="u-textColorDark u-fontWeightBold u-fontSize18 u-cursorPointer">
                          {v.name}
                        </div>
                        <div className="u-flexCenter u-marginTop5">
                          <div className="u-marginRight5 u-textColorDarker u-marginBottom2">
                            Pick Up Address:
                          </div>
                          <div>
                            {v.address}
                            {v.city ? ", " + v.city : ""}
                            {v.state ? ", " + v.state : ""}
                            {v.postal_code ? ", " + v.postal_code : ""}
                          </div>
                        </div>
                        {v.min_order ? (
                          <div className="u-flexCenter u-marginTop5">
                            <div className="u-textColorDarker">
                              Min. order:
                            </div>
                            <div className="u-marginLeft5">$ {v.min_order}</div>
                          </div>
                        ) : null}
                        <div className={"u-marginTop10 u-marginBottom5 u-flexColumn " + (!v.delivery_cost_json || v.delivery_cost_json.length < 10 ? "u-hide" : " ")}>
                          <span className="u-textColorDarker u-marginBottom5">Delivery Cost:</span>
                          <DeliveryCostPart defaultCost={v.cost}
                            costJson={v.delivery_cost_json}
                            itemClassName="u-textAlignRight u-fontSize10" />
                        </div>
                        {v.description ? (
                          <div className="u-marginTop15">

                            <div>{v.description ? renderHTML(v.description) : ""}</div>
                          </div>
                        ) : null}
                        {
                          !isMobile ?
                            <div className="u-marginTop10">
                                <Link href={"/stores/" + (v.slug || itemIdValue)}>
                                  <Button
                                    className="button-secondary u-minWidth160 u-height40"
                                    variant="outlined"
                                  >
                                    Order
                                  </Button>
                                </Link>
                            </div>
                            :
                            null
                        }

                      </div>
                      <div>
                        {
                          v.lat && v.lng ?
                            <div className="u-xs-paddingVertical15 u-marginLeft20 u-xs-marginLeft0">
                              <PickUpMap isMobile={isMobile} lat={v.lat} lng={v.lng} />
                            </div>
                            :
                            null
                        }


                        {
                          isMobile ?
                            <div className="u-marginTop10">
                              <Link href={"/stores/" + (v.slug || itemIdValue)}>
                                <Button
                                  className="button-secondary u-minWidth160 u-height40"
                                  variant="outlined"
                                >
                                  Order
                                </Button>
                              </Link>
                            </div>
                            :
                            null
                        }
                      </div>
                    </div>
                  </ListItem>
                );
              })
            }
          </List>
        </div>
      </div>


    </React.Fragment>
  );
}
